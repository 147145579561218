<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'dark';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'dark';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section 
    class="flex flex-col justify-center items-center py-8"
>
    <!-- Image in a rounded frame -->
    <div class="mb-6">
        <img 
            src="@/assets/images/front.png" 
            alt="Arham" 
            class="w-40 h-40 rounded-full object-cover border-0 border-gray-300 shadow-lg"
        />
    </div>
    
    <!-- Text below the image -->
    <h1
        class="font-general-semibold text-3xl md:text-4xl xl:text-5xl text-center text-ternary-dark dark:text-primary-light"
    >
			Full-Stack Developer
    </h1>
    <p
        class="font-general-medium mt-4 text-lg sm:text-xl xl:text-2xl text-center text-gray-500"
    >
	For the past five years, I’ve been learning and working with computer languages to create and shape the online world. Building websites and applications can often feel like bringing ideas to life, and while it may seem complex, I’ve gained the skills and experience to turn almost any idea into reality.
    </p>
</section>

</template>

<style scoped></style>
